body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video {
  width: 100.555%;
  object-fit: cover;
}

.content{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #fcfcfc;
}

.logo {
  width: 50%;
}


/*
===============
Variables
===============
*/

:root {

  --clr-primary-5: hsl(103, 41%, 49%);
}
/*
===============
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*
===============
Reviews
===============
*/
main {
  min-height: 100vh;
  display: grid;
  place-items: center;
}
.title {
  text-align: center;
  margin-bottom: 4rem;
}
.underline {
  height: 0.25rem;
  width: 5rem;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
}
.container {
  width: 80vw;
}
.review {
  padding: 1.5rem 2rem;
  text-align: center;
}
.review:hover {
}
.img-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.service-container {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.person-img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
}
.quote-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transform: translateY(25%);
  background: var(--clr-primary-5);
  color: #ffffff;
}
.service-icon {
  position: relative;
  top: -3rem;
  left: 0;
  width: 6rem;
  height: 6rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transform: translateY(50%);
  background: var(--clr-primary-5);
  color: #ffffff;
}
.img-container::before {
  content: '';
  width: 100%;
  height: 100%;
  background: var(--clr-primary-5);
  position: absolute;
  top: -0.25rem;
  right: -0.5rem;
  border-radius: 50%;
}
.author {
  margin-bottom: 0.25rem;
}
.info {
  margin-bottom: 0.75rem;
}
.prev-btn,
.next-btn {
  font-size: 1.25rem;
  background: transparent;
  border-color: transparent;
  margin: 0 0.5rem;
  cursor: pointer;
}
.prev-btn:hover,
.next-btn:hover {
  color: var(--clr-primary-5);
}

@font-face {
  font-family: 'MarketDeco';
  src: local('MarketDeco'), url(./fonts/Market_Deco.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}